import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { getAuth } from 'firebase/auth';
import {
  Observable,
  Subject,
  Subscription,
  fromEvent,
  interval,
  switchMap,
  takeUntil,
} from 'rxjs';
import { MainService } from './services/main.service';
import { UserService } from './services/user.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('void', style({ opacity: 0 })),
      transition('void <=> *', animate('500ms ease-in-out')),
    ]),
    trigger('fadeOut', [
      state('*', style({ opacity: 1 })),
      transition(
        '* => void',
        animate('500ms ease-in-out', style({ opacity: 0 }))
      ),
    ]),
  ],
})
export class AppComponent implements OnInit {
  title = 'IonBase_Portal';

  isLoading: boolean = false;
  loadingCursor: boolean = false;
  isDisabled: boolean = false;
  loadingInfo: string = '';
  onlineEvent: Observable<Event> | undefined;
  offlineEvent: Observable<Event> | undefined;

  subscriptions: Subscription[] = [];

  destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    public mainService: MainService,
    public userService: UserService
  ) {}

  ngOnInit() {
    this.applyDynamicBackground();
    this.applyDynamicWatermark();

    if (!getAuth()) {
      let previousRoute = this.mainService.getPreviousRoute();

      if (
        previousRoute.includes('/select-policy') ||
        previousRoute.includes('/transaction-pdf') ||
        previousRoute.includes('/pdf-policy')
      ) {
        this.router.navigateByUrl('/login');
      } else {
        this.router.navigateByUrl('/join');
      }
    }

    this.mainService.isLoading$.subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
    this.mainService.loadingInfo$.subscribe((loadingInfo) => {
      this.loadingInfo = loadingInfo;
    });

    this.mainService.isDisabled$.subscribe((isDisabled) => {
      this.isDisabled = isDisabled;
    });

    this.mainService.loadingCursor$.subscribe((loadingCursor) => {
      this.loadingCursor = loadingCursor;
    });
  }

  get showLogoutButton(): boolean {
    const currentRoute = this.router.url;
    if (
      this.userService.getAuth().currentUser &&
      !currentRoute.startsWith('/policy-member-details')
    ) {
      return true;
    } else {
      return false;
    }
  }

  get showLoginButton(): boolean {
    const currentRoute = this.router.url;

    if (currentRoute.startsWith('/join')) {
      return true;
    } else {
      return false;
    }
  }

  get showJoinButton(): boolean {
    const currentRoute = this.router.url;

    if (currentRoute.startsWith('/login')) {
      return true;
    } else {
      return false;
    }
  }

  routeToLogin() {
    this.router.navigateByUrl('/login');
  }

  routeToJoin() {
    this.router.navigateByUrl('/join');
  }

  applyDynamicBackground() {
    const backgroundImage = environment.backgroundImage;
    document.body.style.backgroundImage = `url(${backgroundImage})`;
  }

  applyDynamicWatermark() {
    const watermarkImage = environment.watermarkImage;
    const styleElement = document.createElement('style');
    styleElement.innerHTML = `
      body::before {
        background-image: url(${watermarkImage}) !important;
      }
    `;
    document.head.appendChild(styleElement);
  }

  // this.onlineEvent = fromEvent(window, 'online');
  // this.offlineEvent = fromEvent(window, 'offline');
  // this.rolesRightsService.connected = true;

  // this.subscriptions.push(
  //   this.onlineEvent.subscribe((e) => {
  //     this.zone.run(() => {
  //       this.snackBarService.openWhiteSnackBar('BACK ONLINE');
  //       this.rolesRightsService.connected = true;
  //     });
  //   })
  // );

  // this.subscriptions.push(
  //   this.offlineEvent
  //     .pipe(switchMap(() => interval(5000).pipe(takeUntil(this.onlineEvent))))
  //     .subscribe((e) => {
  //       this.zone.run(() => {
  //         this.snackBarService.openRedSnackBar(
  //           'CONNECTION LOST PLEASE CALL SEAN'
  //         );
  //         this.rolesRightsService.connected = false;
  //       });
  //     })
  // );
}

export const appVersion = '2024.11.18'; // Replace appVersion with the actual version from your package.json
