import { Component, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-custom-spinner',
  templateUrl: './custom-spinner.component.html',
  styleUrls: ['./custom-spinner.component.scss'],
})
export class CustomSpinnerComponent {
  @Input() size: string = '100px';

  get shadowClass() {
    return environment.name === 'NLA' ? 'nla-shadow' : 'wisani-shadow';
  }

  get logoUrl() {
    return environment.name === 'NLA'
      ? 'assets/images/nlaFlower.svg'
      : 'assets/images/wisaniDove.svg';
  }

  get logoClass() {
    return environment.name === 'NLA' ? 'nla-logo' : 'wisani-logo';
  }
}
