import { Component, Inject } from '@angular/core';
import {
  BankAccountTypes,
  DebitOrder,
  DebitOrderDeductionsDays,
  Member,
  bankBranchMap,
  branchCodeBankMap,
} from '../../../models/policy.model';
import { PolicyService } from '../../../services/policy.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { ValidationService } from '../../../services/validation.service';
import { SnackBarService } from '../../../services/snack-bar.service';
import { UserService } from '../../../services/user.service';
import { takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { DateTimeService } from '../../../services/date-time.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrl: './dialog.component.scss',
})
export class DialogComponent {
  dialogType: string | undefined;
  primaryMember: Member | undefined;
  banks: string[] | undefined;
  branchCodes: string[] | undefined;
  selectedItem: any;
  debitOrderForm: FormGroup | undefined;
  index: number | undefined;
  isTsAndCsChecked: boolean = false;

  bankAccountTypes = BankAccountTypes;
  allowedDebitOrderDeductionDays = DebitOrderDeductionsDays;

  debitOrderTsCs: string = 'https://www.wisani.co.za/terms-conditions/';

  constructor(
    public policyService: PolicyService,
    private fb: FormBuilder,
    public validationService: ValidationService,
    public dateTimeService: DateTimeService,
    private snackBarService: SnackBarService,
    public dialogRef: MatDialogRef<DialogComponent>,
    public dialog: MatDialog,
    private router: Router,
    public userService: UserService,

    @Inject(MAT_DIALOG_DATA) data: DialogComponent
  ) {
    //Data Passed from the component {
    this.selectedItem = data.selectedItem;
    this.dialogType = data.dialogType;
    this.index = data.index;
    this.userService.destroy$
      .pipe(takeUntil(this.userService.ngUnsubscribe$))
      .subscribe(() => this.closeDialog());
  }

  ngOnInit() {
    if (this.dialogType === 'debitOrderDialog') {
      this.primaryMember = this.policyService.getPolicyPrimaryMember(
        this.selectedItem
      );
      this.banks = Object.keys(bankBranchMap);
      this.branchCodes = Object.values(bankBranchMap);

      this.initializeDebitOrderForm();
    }
  }

  //Close Dialog Do Nothing {
  closeDialog(): void {
    this.dialogRef.close();
  }
  //Close Dialog Do Nothing }

  initializeDebitOrderForm() {
    this.debitOrderForm = this.fb.group({
      bank: ['', Validators.required],
      branchCode: ['', Validators.required],
      accountNumber: ['', Validators.required],
      accountType: ['', Validators.required],
      accountName: ['', Validators.required],
      accountHolderId: ['', Validators.required],
      deductionDay: ['', Validators.required],
      changeImmediately: ['', Validators.required],
      status: ['', Validators.required],
      referenceNumber: ['', Validators.required],
    });

    // Set initial values and subscriptions for the form
    this.setInitialFormValues();
    this.setupFormSubscriptions();
  }

  setInitialFormValues() {
    // Set initial values for the form controls
    this.debitOrderForm
      ?.get('accountNumber')
      ?.setValue(
        this.policyService.selectedPolicy?.debitOrder
          ? this.policyService.selectedPolicy.debitOrder.accountNumber
          : ''
      );

    this.debitOrderForm
      ?.get('accountType')
      ?.setValue(
        this.policyService.selectedPolicy?.debitOrder?.accountType
          ? this.policyService.selectedPolicy.debitOrder.accountType
          : 1
      );

    this.debitOrderForm
      ?.get('accountName')
      ?.setValue(
        this.policyService.selectedPolicy?.debitOrder
          ? this.policyService.selectedPolicy.debitOrder.accountName
          : this.primaryMember?.firstName
          ? `${this.primaryMember.firstName[0]} ${this.primaryMember.firstName}`
          : ''
      );

    this.debitOrderForm
      ?.get('accountHolderId')
      ?.setValue(
        this.policyService.selectedPolicy?.debitOrder
          ? this.policyService.selectedPolicy.debitOrder.accountHolderId
          : this.primaryMember
          ? this.primaryMember.idNumber
          : ''
      );

    this.debitOrderForm
      ?.get('deductionDay')
      ?.setValue(
        this.policyService.selectedPolicy?.debitOrder
          ? this.policyService.selectedPolicy.debitOrder.deductionDay
          : 1
      );

    this.debitOrderForm
      ?.get('changeImmediately')
      ?.setValue(
        this.policyService.selectedPolicy?.debitOrder
          ? this.policyService.selectedPolicy.debitOrder.changeImmediately
          : false
      );

    this.debitOrderForm
      ?.get('status')
      ?.setValue(
        this.policyService.selectedPolicy?.debitOrder
          ? this.policyService.selectedPolicy.debitOrder.status
          : 'ACTIVE'
      );

    this.debitOrderForm
      ?.get('referenceNumber')
      ?.setValue(
        this.policyService.selectedPolicy?.debitOrder
          ? this.policyService.selectedPolicy.debitOrder.referenceNumber
          : this.policyService.selectedPolicy?.policyNumber
      );

    // For the bank, set the value based on the selectedPolicy.debitOrder if it exists
    this.debitOrderForm
      ?.get('bank')
      ?.setValue(
        this.policyService.selectedPolicy?.debitOrder
          ? this.policyService.selectedPolicy.debitOrder.bank
          : ''
      );

    // For the branchCode, set the value based on the selectedPolicy.debitOrder if it exists
    this.debitOrderForm
      ?.get('branchCode')
      ?.setValue(
        this.policyService.selectedPolicy?.debitOrder
          ? this.policyService.selectedPolicy.debitOrder.branchCode
          : ''
      );
  }

  setupFormSubscriptions() {
    // Subscription for bank value changes
    this.debitOrderForm
      ?.get('bank')!
      .valueChanges.subscribe((bank: keyof typeof bankBranchMap) => {
        const branchCode =
          this.policyService.selectedPolicy?.debitOrder &&
          this.policyService.selectedPolicy.debitOrder.bank === bank
            ? this.policyService.selectedPolicy.debitOrder.branchCode
            : bankBranchMap[bank];

        if (branchCode) {
          this.debitOrderForm
            ?.get('branchCode')!
            .setValue(branchCode, { emitEvent: false });
        }
        this.setAccountType();
      });

    // Subscription for account number changes
    this.debitOrderForm?.get('accountNumber')!.valueChanges.subscribe(() => {
      this.setAccountType();
    });

    // Subscription for branch code changes
    this.debitOrderForm
      ?.get('branchCode')!
      .valueChanges.subscribe((branchCode) => {
        let banksForBranchCode = branchCodeBankMap[branchCode];
        let selectedBank = null;

        if (this.policyService.selectedPolicy?.debitOrder) {
          const debitOrderBank =
            this.policyService.selectedPolicy.debitOrder.bank;
          if (
            debitOrderBank !== undefined &&
            banksForBranchCode.includes(debitOrderBank)
          ) {
            selectedBank = debitOrderBank;
          }
        }

        if (
          !selectedBank &&
          banksForBranchCode &&
          banksForBranchCode.length > 0
        ) {
          // Logic to determine which bank to select if there are multiple options
          selectedBank = banksForBranchCode[0]; // This is a simple default. Adjust as needed.
        }

        if (selectedBank) {
          this.debitOrderForm
            ?.get('bank')!
            .setValue(selectedBank, { emitEvent: false });
        } else {
          console.log('No Bank found for Branch Code:', branchCode);
          // Handle the case where no bank is determined
        }
        this.setAccountType();
      });
  }

  setAccountType() {
    const bank = String(this.debitOrderForm?.get('bank')?.value);
    const accountNumber = this.debitOrderForm?.get('accountNumber')?.value;

    let accountType = '1'; // Default to CHEQUE/CURRENT

    if (bank === 'NEDBANK' || bank === 'NEDBANK CORPORATE SAVER ACCOUNT') {
      if (accountNumber && accountNumber.startsWith('9')) {
        accountType = '2'; // Savings
        this.debitOrderForm
          ?.get('branchCode')!
          .setValue('720026', { emitEvent: false });
        this.debitOrderForm
          ?.get('bank')!
          .setValue('NEDBANK CORPORATE SAVER ACCOUNT', { emitEvent: false });
      } else if (accountNumber && accountNumber.startsWith('1')) {
        accountType = '1'; // CHEQUE/CURRENT
        // Reset bank and branch code to NEDBANK and its default branch code
        this.debitOrderForm
          ?.get('branchCode')!
          .setValue('198765', { emitEvent: false });
        this.debitOrderForm
          ?.get('bank')!
          .setValue('NEDBANK', { emitEvent: false });
      } else if (accountNumber && accountNumber.startsWith('2')) {
        accountType = '2'; // Savings
        // Reset bank and branch code to NEDBANK and its default branch code
        this.debitOrderForm
          ?.get('branchCode')!
          .setValue('198765', { emitEvent: false });
        this.debitOrderForm
          ?.get('bank')!
          .setValue('NEDBANK', { emitEvent: false });
      }
    } else if (
      ['POSTBANK', 'CAPITEC BANK LIMITED', 'TYME BANK'].includes(bank)
    ) {
      accountType = '2'; // Savings
    }

    this.debitOrderForm
      ?.get('accountType')!
      .setValue(accountType, { emitEvent: false });
  }

  hasDebitOrderFormChanged() {
    if (!this.selectedItem.debitOrder) {
      return true;
    }

    const formValue = this.debitOrderForm?.getRawValue(); // Assuming this is how you get your form values

    return Object.keys(formValue).some((key) => {
      // Ensure the key is a keyof DebitOrder to satisfy TypeScript type checking
      return (
        formValue[key] !== this.selectedItem.debitOrder[key as keyof DebitOrder]
      );
    });
  }

  get getNextDebitOrderPaymentDate() {
    const deductionDay = this.debitOrderForm?.value.deductionDay;

    if (!deductionDay) {
      return 'SELECT A DEDUCTION DAY';
    }

    // Formatting the date as a string for display
    const nextPaymentDateString = this.dateTimeService.formatDate(
      this.dateTimeService.getTargetDay(deductionDay)
    );
    return `NEXT PAYMENT WILL OCCUR ON ${nextPaymentDateString}`;
  }

  async updateDebitOrder() {
    if (this.isTsAndCsChecked) {
      if (
        this.debitOrderForm?.valid &&
        this.policyService.selectedPolicy &&
        !(
          this.policyService.selectedPolicy.status === 'INACTIVE' ||
          this.policyService.selectedPolicy.status === 'LAPSED'
        )
      ) {
        if (this.policyService.selectedPolicy.policyNumber) {
          let validAccountNumber =
            await this.validationService.validateBankAccount(
              this.debitOrderForm?.value.accountNumber,
              this.debitOrderForm?.value.branchCode,
              this.debitOrderForm?.value.accountType
            );

          if (validAccountNumber) {
            await this.policyService.updatePolicyDebitOrder(
              this.policyService.selectedPolicy,
              this.debitOrderForm?.value
            );
            this.router.navigate([
              '/policy-pdf',
              this.policyService.selectedPolicy?.id,
            ]);
            this.closeDialog();
          } else {
            this.snackBarService.openRedSnackBar(
              this.debitOrderForm?.value.accountNumber +
                ' IS NOT A VALID ACCOUNT NUMBER!'
            );
          }
        }
      }
    } else {
      this.snackBarService.openRedSnackBar(
        'PLEASE ACCEPT THE TERMS AND CONDITIONS'
      );
    }
  }

  // Use policyService updatePolicyComments to delete a comment out of policy collection
  async deletePolicyMember() {
    if (this.policyService.selectedPolicy && this.index !== undefined) {
      this.closeDialog();
      await this.policyService.updatePolicyMembers(
        this.policyService.selectedPolicy,
        this.selectedItem,
        this.index,
        true
      );
    }
  }

  openDebitOrderDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      dialogType: 'debitOrderDialog',
      selectedItem: this.policyService.selectedPolicy,
    };

    this.dialog.open(DialogComponent, dialogConfig);
  }

  openTermsLink() {
    window.open(this.debitOrderTsCs);
  }

  toggleTsAnsCs() {
    this.isTsAndCsChecked = !this.isTsAndCsChecked;
  }

  routeToJoin() {
    this.router.navigate(['/join']);
    this.closeDialog();
  }
}
