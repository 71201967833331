<!-- CREATE NEW DEBIT ORDER -->
<div
  *ngIf="dialogType === 'debitOrderDialog'"
  class="debitOrderDialog-dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="center-text">
    <div class="veryBold dialogHeading">DEBIT ORDER</div>
  </header>

  <form *ngIf="debitOrderForm" class="flex" [formGroup]="debitOrderForm">
    <div class="spaceBetween">
      <!-- Bank Select -->
      <mat-form-field appearance="outline" class="flex">
        <mat-label>BANK</mat-label>
        <mat-select formControlName="bank">
          <mat-option *ngFor="let bank of banks" [value]="bank">{{
            bank
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Branch Code Select -->
      <mat-form-field appearance="outline" class="flex">
        <mat-label>BRANCH CODE</mat-label>
        <mat-select formControlName="branchCode">
          <mat-option *ngFor="let code of branchCodes" [value]="code">{{
            code
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="offlineContainer">
      <div class="spaceBetween">
        <mat-form-field appearance="outline" class="flex">
          <mat-label>ACCOUNT NUMBER</mat-label>
          <input
            autocomplete="off"
            matInput
            formControlName="accountNumber"
            numericOnly
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="flex">
          <mat-label>ACCOUNT TYPE</mat-label>
          <mat-select formControlName="accountType">
            <mat-option
              *ngFor="let type of bankAccountTypes; let i = index"
              [value]="i + 1"
            >
              {{ type }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="spaceBetween">
        <mat-form-field appearance="outline" class="flex">
          <mat-label>ACCOUNT NAME</mat-label>
          <input
            autocomplete="off"
            type="text"
            oninput="this.value = this.value.toUpperCase()"
            matInput
            formControlName="accountName"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="flex">
          <mat-label>ACCOUNT HOLDER ID</mat-label>
          <input
            autocomplete="off"
            type="text"
            matInput
            formControlName="accountHolderId"
            numericOnly
          />
        </mat-form-field>
      </div>

      <div class="spaceBetween pb15">
        <mat-form-field appearance="outline" class="flex offlineField">
          <mat-label>DEDUCTION DAY</mat-label>
          <mat-select formControlName="deductionDay">
            <mat-option
              *ngFor="let day of allowedDebitOrderDeductionDays"
              [value]="day"
              >{{ day }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <div class="custom-form-field flex pl5 pr5">
          <span class="breakWord veryBold">
            {{ getNextDebitOrderPaymentDate }}
          </span>
        </div>
      </div>

      <div class="custom-form-field borderBottom">
        <span class="breakWord veryBold">
          <input type="checkbox" (click)="toggleTsAnsCs()" />
          <label for="termsCheckbox" style="cursor: pointer">I HAVE READ</label>
          <button (click)="openTermsLink()" class="textLink">
            THE T's & C's
          </button>
        </span>
      </div>
    </div>
  </form>
  <div mat-dialog-actions class="spaceBetweenButtons">
    <button
      mat-flat-button
      color="primary"
      [routerLink]="['/policy-pdf', policyService.selectedPolicy?.id]"
      routerLinkActive="is-active"
      (click)="closeDialog()"
    >
      SKIP
    </button>
    <button
      mat-flat-button
      (keydown.enter)="updateDebitOrder()"
      color="primary"
      class="right-align"
      [disabled]="debitOrderForm?.invalid || !hasDebitOrderFormChanged()"
      (click)="updateDebitOrder()"
    >
      SAVE
    </button>
  </div>
</div>

<!-- NO EFT -->
<div
  *ngIf="dialogType === 'EFTDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
  (keydown.enter)="openDebitOrderDialog()"
>
  <header class="header center-text">
    <div class="dialogHeading veryBold">PLEASE BE INFORMED</div>
    <div class="warningText dialogText pb15 pr45 pl45">
      WE ARE NO LONGER ACCEPTING EFT PAYMENTS
    </div>
    <div class="dialogText">KINDLY SETUP YOUR DEBIT ORDER</div>
    <div class="dialogText">ON THE NEXT POP-UP</div>
  </header>
  <div>
    <div mat-dialog-actions>
      <div>
        <button
          mat-flat-button
          color="primary"
          class="Montserrat"
          (click)="closeDialog()"
        >
          CANCEL
        </button>
      </div>
      <button
        mat-flat-button
        color="primary"
        (click)="closeDialog(); openDebitOrderDialog()"
        class="right-align Montserrat"
      >
        NEXT
      </button>
    </div>
  </div>
</div>

<!-- DELETE MEMBER DIALOG -->
<div
  *ngIf="dialogType === 'deleteMemberDialog'"
  class="deleteMemberDialog-dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="center-text">
    <div class="warningText pb5">ARE YOU SURE YOU</div>
    <div class="warningText pb15">WANT TO DELETE</div>
    <h1 class="dialogText pb15">
      {{ selectedItem.firstName + " " + selectedItem.lastName | uppercase }}
    </h1>
  </header>
  <div class="dialogBottom">
    <div mat-dialog-actions class="spaceBetweenButtons">
      <button mat-flat-button color="primary" (click)="closeDialog()">
        CANCEL
      </button>
      <div>
        <button mat-flat-button color="warn" (click)="deletePolicyMember()">
          DELETE
        </button>
      </div>
    </div>
  </div>
</div>

<!-- NEW MEMBER LOGIN -->
<div
  *ngIf="dialogType === 'newMemberLoginDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
  (keydown.enter)="routeToJoin()"
>
  <header class="header">
    <div class="dialogText center-text">NO POLICY FOUND</div>
    <div class="dialogText center-text">MATCHING YOUR CREDENTIALS</div>
    <div class="dialogText center-text">WOULD YOU LIKE TO JOIN?</div>
  </header>
  <div>
    <div mat-dialog-actions>
      <div>
        <button
          mat-flat-button
          color="primary"
          class="Montserrat"
          (click)="closeDialog()"
        >
          CANCEL
        </button>
      </div>
      <button
        mat-flat-button
        color="primary"
        (click)="routeToJoin()"
        class="right-align Montserrat"
      >
        JOIN
      </button>
    </div>
  </div>
</div>

<!-- NLA CONTACT INFORMATION -->
<div
  *ngIf="dialogType === 'nlaContactInformationDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="header">
    <div class="dialogText center-text">PLEASE CALL 015 307 6503</div>
    <div class="dialogText center-text">OR WHATSAPP 078 772 3361</div>
    <div class="dialogText center-text">FOR AMENDMENT QUERIES</div>
  </header>
  <div>
    <div mat-dialog-actions class="center-text">
      <div>
        <button
          mat-flat-button
          color="primary"
          class="Montserrat"
          (click)="closeDialog()"
        >
          CLOSE
        </button>
      </div>
    </div>
  </div>
</div>
